import React, { useEffect, useState } from 'react';
import { Container } from "react-bootstrap";
import Layout from '../modules/layout';
import { getTranslation } from '../helper/translation';
import SearchResults from '../modules/search/searchResults';
import SEO from '../modules/seo'

const NOINDEX_META_TAG = '<meta name="robots" content="noindex">'

const SearchPage = ({ pageContext }) => {
  const [loaded, setIsLoaded] = useState(false)

  useEffect(() => {
    setIsLoaded(true)
  }, [])

  return(
    <Layout settings={pageContext?.settings}>
       <SEO
          metaTags={NOINDEX_META_TAG}
          title={getTranslation('search.title', pageContext?.settings?.microcopy)}
        />
      <Container fluid className={'py-4'}>
        <div description={'desc.searchPage'}>
          <SearchResults/>
        </div>
      </Container>
    </Layout>
  )
  
};

export default SearchPage;