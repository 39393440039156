import React from "react";

const Spinner = () => {
    return (
        <span className='spinner-wrapper'>
            <span className='spinner'/>
        </span>
    );
}

export default Spinner;
