import { graphql, useStaticQuery } from 'gatsby';
import _ from 'lodash';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';
import { useFlexSearch } from 'react-use-flexsearch';
import { StringParam, useQueryParams } from 'use-query-params';
import Button from '../../components/button';
import Spinner from '../../components/spinner';
import { getTranslation } from '../../helper/translation';
import { SettingsContext } from '../../modules/layout';
import SearchBar from '../search/searchBar';
import SearchCard from './searchCard';

const SearchResults = () => {
  const [resultsCount, setResultsCount] = useState(null);
  const [firstResultsCount, setFirstResultsCount] = useState(18);
  const [querySearchTerm, setQuerySearchTerm] = useQueryParams({
    find: StringParam
  });

  const { microcopy, node_locale } = useContext(SettingsContext);
  const [isLoadMore, setIsLoadMore] = useState(false);
  const [searchResults, setSearchResults] = useState(null);

  const localizedMicrocopy = microcopy;
  const isDesktop = useMediaQuery({ minWidth: 768 });

  const {
    current: {
      localSearchPages: { index, store }
    }
  } = useRef(
    useStaticQuery(graphql`
      {
        localSearchPages {
          index
          store
        }
      }
    `)
  );

  const allFlexSearchResults = useFlexSearch(
    querySearchTerm.find,
    index,
    store
  );

  useEffect(() => {
    const flexSearchResultsNotSorted = allFlexSearchResults.filter(
      (page) => page.node_locale === node_locale
    );
    setResultsCount(flexSearchResultsNotSorted.length);
    setFirstResultsCount(isDesktop ? firstResultsCount : firstResultsCount / 3);
    setIsLoadMore(
      flexSearchResultsNotSorted.length >
        (isDesktop ? firstResultsCount : firstResultsCount / 3)
    );
    const sortedSearchResults = flexSearchResultsNotSorted.sort(
      sortSearchResults(querySearchTerm)
    );
    setSearchResults(sortedSearchResults);
  }, [allFlexSearchResults, isDesktop]);

  const handleSearchReset = () => {
    setQuerySearchTerm({ find: null });
  };

  const handleSearch = (query) => {
    setQuerySearchTerm({ find: query });
  };

  return (
    <div className="searchResults">
      <h2 className="mb-5">
        {getTranslation('search.suchergebnisse', localizedMicrocopy)}
      </h2>
      <SearchBar
        initSearchTerm={querySearchTerm.find}
        onChange={handleSearch}
        onReset={handleSearchReset}
        placeholder={getTranslation('search.placeHolder', localizedMicrocopy)}
        className={'resultpage'}
        store={store}
      />
      {typeof window === 'undefined' ? (
        <Spinner />
      ) : (
        <div>
          {searchResults?.length ? (
            <div className="py-2">
              {isLoadMore ? (
                <span>
                  1 -
                  {resultsCount > firstResultsCount
                    ? firstResultsCount
                    : searchResults?.length}{' '}
                  {getTranslation('search.from', localizedMicrocopy)}{' '}
                  {searchResults?.lengh}{' '}
                  {getTranslation('search.resultsFor', localizedMicrocopy)}{' '}
                  {querySearchTerm.find}
                </span>
              ) : (
                <span>
                  {searchResults?.length}{' '}
                  {getTranslation('search.resultsFor', localizedMicrocopy)}{' '}
                  {querySearchTerm.find}
                </span>
              )}
            </div>
          ) : (
            <div className="py-2">
              {getTranslation('search.noResults', localizedMicrocopy)}
            </div>
          )}
          <div className="search-result-container my-40">
            <div className="search-grid">
              <Row>
                {isLoadMore
                  ? searchResults &&
                    searchResults.map((result, index) => {
                      if (
                        index < firstResultsCount ||
                        firstResultsCount == null
                      ) {
                        return (
                          <Col key={index} xs={12} md={6} lg={4}>
                            <SearchCard i={index} {...result} key={index} />
                          </Col>
                        );
                      }
                    })
                  : searchResults &&
                    searchResults.map((result, index) => (
                      <Col key={index} xs={12} md={6} lg={4}>
                        <SearchCard i={index} {...result} key={index} />
                      </Col>
                    ))}
              </Row>
              {isLoadMore && (
                <div className={'text-center'}>
                  <Button
                    text={getTranslation('label.loadMore', localizedMicrocopy)}
                    type="secondary"
                    onClick={() => setIsLoadMore(false)}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SearchResults;

function sortSearchResults(querySearchTerm) {
  return (pageA, pageB) => {
    const searchTerm = querySearchTerm.find.toLowerCase();
    const pageAPriorityScore = getPriorityScore(pageA, searchTerm);
    const pageBPriorityScore = getPriorityScore(pageB, searchTerm);

    if (pageAPriorityScore < pageBPriorityScore) {
      return 1;
    } else if (pageAPriorityScore > pageBPriorityScore) {
      return -1;
    } else {
      return 0;
    }
  };
}

function getPriorityScore(page, searchTerm) {
  return (
    isSearchTermOnTeaserCTAH1(page, searchTerm) +
    isSearchTermOnTitle(page, searchTerm) +
    isSearchTermOnSlug(page, searchTerm)
  );
}

function isSearchTermOnSlug(page, searchTerm) {
  if (page?.slug?.toLowerCase()?.includes(searchTerm)) {
    return 1;
  }
  return 0;
}

function isSearchTermOnTitle(page, searchTerm) {
  if (page?.title?.toLowerCase()?.includes(searchTerm)) {
    return 3;
  }

  const titleInComponent = _.get(
    _.find(page?.localizedPage?.components, 'title'),
    'title'
  );
  if (titleInComponent?.toLowerCase()?.includes(searchTerm)) {
    return 2;
  }
  return 0;
}

function isSearchTermOnTeaserCTAH1(page, searchTerm) {
  const pageWithTeaserCTA = page?.localizedPage?.components.find(
    (component) =>
      component.__typename === 'ContentfulComponentTeaserPictureCta'
  );
  if (
    pageWithTeaserCTA?.headlineH1 &&
    pageWithTeaserCTA?.title?.toLowerCase().includes(searchTerm)
  ) {
    return 5;
  }
  return 0;
}
